//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import { FormMixin, default as FormValidatable } from "@/components/Form/Form";
export default {
  mixins: [ModalMixin, FormMixin],
  components: {
    Modal,
    FormValidatable
  },
  props: {},
  data() {
    return {
      linkId: null,
      email: null,
      loading: false
    };
  },
  mounted() {},
  methods: {
    onOpen({
      linkId,
      email
    }) {
      this.linkId = linkId;
      this.email = email;
    },
    async submit({
      close
    }) {
      try {
        await this.$store.dispatch("user/disableChild", {
          id: this.linkId
        });
        this.$emit("complete");
        close();
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    actions() {
      return ["cancel", {
        name: "submit",
        text: "删除",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading
      }];
    }
  }
};